<template>
  <div class="page-container">
    <div class="container">
      <b-row align-h="between">
        <h1 class="title-1 pt-4 ml-4 ">
          {{ $t('fixedDesks') }}
        </h1>
        <div class="filters-row tar pt-4 mr-4">
          <input
            id="table-filter"
            ref="filter"
            v-model="filter"
            style="width: 180px"
            type="text"
            class="form-control"
            :placeholder="$t('filterTable')"
          />
        </div>
      </b-row>
      <div>
        <b-table
          class="table-style table-settings"
          hover
          :filter="filter"
          :fields="fields"
          :items="desks"
          show-empty
        >
        </b-table>
      </div>
      <b-btn @click="onErrorPressed">Error Test</b-btn>
    </div>
  </div>
</template>

<script>
import OkkuApi from '@/services/OkkuApi'

export default {
  name: 'FixedDesks',
  data() {
    return {
      desks: [],
      filter: '',
      fields: [
        {
          key: 'floor',
          label: this.$t('floor'),
          sortable: true
        },
        {
          key: 'room',
          label: this.$t('room'),
          sortable: true
        },
        {
          key: 'desk',
          label: this.$t('desk'),
          sortable: true
        },
        {
          key: 'regularUser',
          label: this.$t('assignedTo'),
          sortable: true
        }
      ]
    }
  },
  mounted() {
    OkkuApi.getInsightsFixedDeskUsers().then(desks => {
      this.desks = desks
    })
  },
  methods: {
    onErrorPressed() {
      throw new Error('Test Error from Button')
    }
  }
}
</script>
